import { destroyStorage, getFromStorage } from "./componentes/util/storage";
class Auth {
  constructor() {
    this.autenticado = false;
  }

  login(cb) {
    this.autenticado = true;
  }

  logout(cb) {
    const obj = destroyStorage("the_main_app" + process.env.REACT_APP_COOKIE);
    if (obj == null) {
      this.autenticado = false;
    } else {
      this.autenticado = false;
    }
    cb();
  }
  isAutenticado() {
    const obj = getFromStorage("the_main_app" + process.env.REACT_APP_COOKIE);
    if (obj && obj.token) {
      this.autenticado = true;
    } else {
      this.autenticado = false;
    }
    return this.autenticado;
  }
}
export default new Auth();
